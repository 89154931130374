var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import LoadingTableRow from './LoadingTableRow';
export default function LoadingTable(_a) {
    var fields = _a.fields, _b = _a.rowsPerPage, rowsPerPage = _b === void 0 ? 5 : _b, _c = _a.noAvatar, noAvatar = _c === void 0 ? false : _c, height = _a.height;
    return (_jsx(_Fragment, { children: __spreadArray([], new Array(rowsPerPage || 5), true).map(function (_, index) { return (_jsx(LoadingTableRow, { height: height, fields: fields, avatarIndex: noAvatar ? undefined : 0 }, index)); }) }));
}
