var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Avatar, Skeleton, Stack, TableCell, TableRow, Typography } from '@mui/material';
export default function LoadingTableRow(_a) {
    var avatarIndex = _a.avatarIndex, fields = _a.fields, height = _a.height;
    return (_jsx(TableRow, __assign({ sx: __assign({}, (height && {
            height: height,
        })) }, { children: __spreadArray([], new Array(fields || 5), true).map(function (_, index) {
            if (avatarIndex && avatarIndex === index) {
                return (_jsx(TableCell, { children: _jsx(Stack, __assign({ direction: "row", alignItems: "center", spacing: 2 }, { children: _jsx(Skeleton, __assign({ variant: "circular" }, { children: _jsx(Avatar, {}) })) })) }, index));
            }
            return (_jsx(TableCell, __assign({ align: "left", sx: { textTransform: 'capitalize' } }, { children: _jsx(Skeleton, __assign({ width: "100%" }, { children: _jsx(Typography, { children: "." }) })) }), index));
        }) })));
}
